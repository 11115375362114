<template>
<div class="pi-container" v-if="patientInfo">
    <div class="pi-title">
        <el-image class="pi-title-img" src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/e66cc49c8b8d999afe230160eae510d1.png" fit="fit"></el-image>
        <div class="pi-title-name">基本信息</div>
    </div>
    <div class="pi-content">编号: {{patientInfo.number2?patientInfo.number2:patientInfo.number}}</div>
    <div class="pi-content">姓名: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'name')}}</div>
    <div class="pi-content">性别: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'hzxb')}}</div>
    <div class="pi-content">年龄: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'hznl')}}</div>
    <div class="pi-content">电话: {{patientInfo.user.username}}</div>
    <div class="pi-content">医院: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'hospital')}}</div>
    <div class="pi-content">科室: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'keshi')}}</div>
    <div class="pi-content">处方医生: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'cfys')}}</div>
    <div class="pi-content">诊断: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'syz')}}</div>
    <div class="pi-content">合并症: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'jcjbzd')}}</div>
  <div class="pi-content">首次注射: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'sczs')}}</div>
    <div class="pi-content">体重: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'hztz')}}kg</div>
    <div class="pi-content">Ige指数: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'ige')}}</div>
    <div class="pi-content">用药剂量: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'yyfa')}}</div>
    <div class="pi-content">用药频次: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'yypc')}}</div>
    <div class="pi-content">入组时间: {{getColumnValue(patientInfo.ruzuSurveyData.survey, 'rzrq')}}</div>
    <div class="pi-content">末次注射: {{getColumnValue(patientInfo.suifangSurveyData.survey, 'mczs', true)}}</div>
</div>
</template>

<script>
import surveyUtil from "@/common/surveyUtil";

export default {
    name: "PatientInfo",
    data() {
        return {
            patientInfo: undefined,
        }
    },
    props:{
        patientId:{
            type: String,
            required: true
        }
    },
    created() {
        this.fetchPatientInfo()
    },
    methods: {
        fetchPatientInfo() {
            this.$httpUtil.get(this.$urlConstant.nuohua.patientInfo + this.patientId, res => {
                this.patientInfo = res.data
            }, this)
        },
        getName() {
            if (this.patientInfo) {
                const name = this.patientInfo.user.realName?this.patientInfo.user.realName:this.patientInfo.user.nickname

                return name
            }
            return ''
        },
        getColumnValue(survey, propertyId,notReturnHidden) {
            return surveyUtil.findPropertyValueById(survey, propertyId,notReturnHidden)
        }
    }
}
</script>

<style scoped>
.pi-container {
    font-family: Helvetica;
    color: #FFFFFF;
    padding: 13px 20px;
}
.pi-title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.pi-title .pi-title-name {
    font-size: 14px;
    margin-left: 9px;
}
.pi-title-img {
    width: 34px;
    height: 31px;
}
.pi-content {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: left;
    margin-top: 20px;
}

</style>