<template>
    <div class="id-container">
        <div class="label-text">{{ property.abbreviation ? property.abbreviation : property.name }}:</div>
        <div v-if="files.length > 0">
            <el-link v-for="file in files" :key="file.url" :href="file.url" target="_blank" type="primary">{{file.name}}</el-link>
        </div>
        <div v-else>无文件</div>
    </div>
</template>

<script>
export default {
    name: "YtFileDisplay",
    props: {
        property: {
            type: Object,
            required: true
        },
    },
    computed: {
        surveyPropertyType() {
            return this.$constant.surveyPropertyType
        },
        files() {
            if (!this.property.propertyData) {
                return []
            }
            if (this.property.type !== this.$constant.surveyPropertyType.file) {
                console.log('类型错误')
                return []
            }
            return this.property.propertyData.fileUrls?this.property.propertyData.fileUrls:[]
        }
    },
}
</script>

<style scoped>
.id-container {
    display: flex;
    flex-direction: row;
}
.label-text {
    margin-right: 5px;
    text-align: left;
    white-space: nowrap;
}
</style>