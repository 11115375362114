<template>
    <div class="id-container">
        <div class="label-text">{{ property.abbreviation ? property.abbreviation : property.name }}:</div>
        <el-image class="id-image" v-if="images.length > 0" :src="images[0]" fit="cover" :preview-src-list="images"></el-image>
        <div v-else>无照片</div>
    </div>
</template>

<script>
export default {
    name: "YtImageDisplay",
    props: {
        property: {
            type: Object,
            required: true
        },
    },
    computed: {
        surveyPropertyType() {
            return this.$constant.surveyPropertyType
        },
        images() {
            if (!this.property.propertyData) {
                return []
            }
            if (this.property.type !== this.$constant.surveyPropertyType.image) {
                console.log('类型错误')
                return []
            }
            return this.property.propertyData.imgUrls?this.property.propertyData.imgUrls:[]
        }
    },
    methods: {}
}
</script>

<style scoped>
.id-container {
    display: flex;
    flex-direction: row;
}
.label-text {
    margin-right: 5px;
    text-align: left;
    white-space: nowrap;
}
.id-image {
    max-height: 50px;
}
</style>