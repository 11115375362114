<template>
    <div class="pdc-container">
        <div class="pdc-left">
            <div class="pdc-dot"></div>
            <div class="pdc-line"></div>
        </div>
        <div class="pdc-right" v-if="survey">
            <div class="pdc-time">{{ surveyDataName }}   表单生成时间:{{survey.surveyData.createdTime | formatDate}}</div>
            <div class="pdc-panel">
                <div class="pdc-panel-inner">
                    <template v-for="(property,index) in survey.properties">
                        <div :key="index" class="pdc-panel-line" v-if="hidePropertyData(property.propertyData)">
                            <YtImageDisplay v-if="property.type === surveyPropertyType.image"
                                            :property="property"></YtImageDisplay>
                            <YtFileDisplay v-if="property.type === surveyPropertyType.file"
                                           :property="property"></YtFileDisplay>
                            <YtDefaultDisplay
                                    v-if="property.type !== surveyPropertyType.image && property.type !== surveyPropertyType.file"
                                    :property="property"></YtDefaultDisplay>
                        </div>
                    </template>
                </div>
                <div>
                    <el-image class="pdc-edit" @click="doEdit" v-if="isAdmin"
                              src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/1a71a253b8e41ed178ea070872cf2ebc.png"></el-image>
                </div>
            </div>
        </div>
        <div class="pdc-right" v-else>
            <div class="pdc-time">{{ currentDate | formatDate }}</div>
            <div class="pdc-new">
                新增一次注射引导
            </div>
        </div>
    </div>
</template>

<script>
import YtImageDisplay from "@/components/survey/components/properties/imageInput/YtImageDisplay";
import YtDefaultDisplay from "@/components/survey/components/properties/YtDefaultDisplay";
import YtFileDisplay from "@/components/survey/components/properties/fileInput/YtFileDisplay";

export default {
    name: "SurveyDisplay",
    components: {
        YtFileDisplay,
        YtDefaultDisplay,
        YtImageDisplay
    },
    data() {
        return {}
    },
    props: {
        survey: {
            type: Object,
            required: false
        },
        attachments: {
            type: Array,
            required: false
        },
        showSerial: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        surveyPropertyType() {
            return this.$constant.surveyPropertyType
        },
        surveyDataName() {
            if (this.showSerial && this.survey.surveyData.indexInOwnerUser && this.survey.surveyData.indexInOwnerUser > 0) {
                return '第' + this.survey.surveyData.indexInOwnerUser + '次' + this.survey.name
            }
            return this.survey.name
        },
        isAdmin() {
            // return this.$sess.getCurrentDoctor().admin
            return true
        },
        currentDate() {
            return new Date()
        },
    },
    created() {
    },

    mounted() {
    },

    methods: {
        doEdit() {
            this.$router.push({
                name: 'Survey', query: {
                    survey: this.survey.id,
                    'survey-data': this.survey.surveyData.id
                }
            })
        },
        hidePropertyData(propertyData) {
            return propertyData && !propertyData.hidden
        }
    },
}
</script>

<style scoped>
.pdc-container {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}

.pdc-left {
    width: 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdc-dot {
    width: 14px;
    height: 14px;
    background: #556BE5;
    box-shadow: inset 0 1px 3px 0 rgba(255, 255, 255, 0.50), inset 0 1px 3px 0 #0C757A;
    border-radius: 100%;
}

.pdc-line {
    width: 1px;
    background-color: #77BEFE;
    height: 100%;
}

.pdc-right {
    margin-bottom: 60px;
    flex: 1;
}

.pdc-time {
    margin-bottom: 10px;
    text-align: left;
    font-family: Helvetica;
    font-size: 14px;
    color: #556BE5;
    letter-spacing: 1.04px;
}

.pdc-panel {
    background: #FFFFFF;
    border-radius: 6px;
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #1B243F;
    letter-spacing: 0;
    padding: 11px 16px;
    margin-right: 35px;
    justify-content: space-between;
}

.pdc-panel-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    justify-content: space-between;
}

.pdc-panel-line {
    display: flex;
    flex-direction: row;
    width: 45%;
    padding: 5px 0;
}

.pdc-edit {
    width: 26px;
    height: 26px;
    cursor: pointer;
}
.pdc-new {
    background: #F2F3F7;
    box-shadow: 0 0 2px 0 rgba(85,107,229,0.30), inset 0 -2px 4px 0 #FFFFFF, inset 0 4px 8px 0 #ABB8D9;
    border-radius: 8px;
    width: 184px;
    height: 42px;
    line-height: 50px;
    font-family: Helvetica;
    font-size: 15px;
    color: #1B243F;
    letter-spacing: 1.12px;
    text-align: center;
    cursor: pointer;
}
</style>