<template>
<div>
    <nh-title title="患者详情"></nh-title>
    <div class="pd-content">
        <div class="pd-left">
            <PatientInfo v-if="patientId" :patient-id="patientId"></PatientInfo>
        </div>
        <div class="pd-right">
            <div class="pd-menu-container">
                <div :class="survey.id===selectedMenu.id?'pd-menu-selected':'pd-menu'" v-for="survey in menuList" :key="survey.id" @click="filterSurvey(survey)">
                    {{survey.name}}
                </div>
            </div>
            <div class="pd-survey-content">
                <div v-if="showAddBtn" @click="doCreateSurvey">
                    <SurveyDisplay></SurveyDisplay>
                </div>
                <div>
                    <SurveyDisplay v-if="isRuzu && surveyDataList.length > 0" :key="surveyDataList[0].id" :survey="surveyDataList[0].survey" :show-serial="false"></SurveyDisplay>
                    <SurveyDisplay v-else v-for="item in surveyDataList" :key="item.id" :survey="item.survey"></SurveyDisplay>
                </div>
                <div v-if="!isRuzu" style="text-align: right;padding-right: 30px;">
                    <el-pagination
                            layout="total,prev,pager,next"
                            :total="total"
                            :page-size="$urlConstant.pageSize"
                            :current-page="currentPage"
                            @current-change="fetchData">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import StringUtil from "@/common/utils/StringUtil";
import SurveyDisplay from "@/components/survey/components/SurveyDisplay";
import PatientInfo from "@/components/patient/components/patient/PatientInfo";
export default {
    name: "Patient",
    components: {PatientInfo, SurveyDisplay, NhTitle},
    data() {
        return {
            patientId: undefined,
            patient: {},
            surveyDataList: [],
            currentPage: 1,
            total: 0,
            totalPages: 0,
            surveyCaseDatasource: undefined,
            menuList: [],
            selectedMenu: undefined
        }
    },
    computed:{
        showAddBtn() {
            return this.menuList.indexOf(this.selectedMenu) === 2 && !this.$sess.getCurrentDoctor().admin
        },
        isRuzu() {
            return this.menuList.indexOf(this.selectedMenu) === 0
        },
        isAdmin() {
          return this.$sess.getCurrentDoctor().admin
        },
    },
    created() {
        this.patientId = this.$route.query.id
        if (!this.patientId) {
            this.$message.error('参数错误')
            return
        }
        this.fetchMenu(this.currentPage)
    },
    methods:{
        fetchMenu(index) {
            this.$httpUtil.get(this.$urlConstant.nuohua.defaultSurveyConfigList, res=>{
                this.menuList = res.data
                this.selectedMenu = this.menuList[0]
                this.fetchData(index)
            }, this)
        },
        fetchData(index) {
            this.$httpUtil.get(this.$urlConstant.patient.getPatientByDoctor + this.patientId,res=>{
                this.patient = res.data
                this.fetchSurveyData(index)
            },this)
        },
        fetchSurveyData(index) {
            if(!this.patient){
                return
            }
            const params = {
                surveyIds: [this.selectedMenu.id]
            }
            this.$httpUtil.post(StringUtil.stringInject(this.$urlConstant.survey.surveyDataListByOwner, [
                    this.patient.userId,
                    index-1,
                    this.$urlConstant.pageSize
                ]),
                params,
                (res) => {
                    this.surveyDataList = res.data.content
                    this.total = res.data.totalElements
                    this.totalPages = res.data.totalPages
                    this.currentPage = index;
                },
                this
            )
        },
        doCreateSurvey() {
            this.$httpUtil.get(this.$urlConstant.patient.patientConfigSurveyCaseTree,res=>{
                const tree = res.data
                this.surveyCaseDatasource = tree.children
                //TODO 弹出选择框
                if(tree.children.length > 0) {
                    const category = tree.children[0]
                    if(category.children && category.children.length > 0) {
                        const survey = category.children[0]
                        this.$router.push({
                            name: 'Survey',
                            query: {
                                survey: survey.id,
                                ownerUserId: this.patient.userId,
                                patientId: this.patient.id
                            }
                        })
                    }
                }
            },this)
        },
        filterSurvey(survey) {
            this.selectedMenu = survey
            this.currentPage = 1
            this.fetchSurveyData(this.currentPage)
        },
    }
}
</script>

<style scoped>
    .pd-content {
        padding-top: 48px;
        display: flex;
        flex-direction: row;
        background-color: #EBECEE;
    }
    .pd-left {
        background: #656D72;
        box-shadow: -6px -8px 12px 0 #FFFFFF, 6px 8px 12px 0 #ADC1DC;
        width: 180px;
        height: calc(100vh - 88px);
        overflow-y: auto;
    }
    .pd-right {
        background: #EBEBF1;
        box-shadow: 15px -8px 12px 0 #FFFFFF, 6px 8px 12px 0 #ADC1DC;
        flex: 1;
    }
    .pd-menu-container {
        display: flex;
        flex-direction: row;
    }
    .pd-menu {
        background: #F2F3F7;
        box-shadow: inset 0 -2px 4px 0 #FFFFFF, inset 0 4px 8px 0 #ABB8D9;
        font-family: Helvetica;
        font-size: 15px;
        color: #656D72;
        letter-spacing: 1.12px;
        text-align: center;
        width: 184px;
        height: 47px;
        line-height: 47px;
        cursor: pointer;
    }
    .pd-menu-selected {
        background: #556BE5;
        box-shadow: inset 0 -2px 2px 0 #FFFFFF, inset 0 4px 8px 0 #28407C;
        font-family: Helvetica;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 1.12px;
        text-align: center;
        width: 184px;
        height: 47px;
        line-height: 47px;
        cursor: pointer;
    }
    .pd-new {
        flex: 1;
        text-align: right;
        margin-right: 16px;
    }
    .pd-new-btn {
        font-family: Helvetica;
        font-size: 15px;
        color: #656D72;
        letter-spacing: 1.12px;
        text-align: center;
        height: 47px;
    }
    .pd-survey-content {
        overflow-y: auto;
        height: calc(100vh - 135px);
    }
</style>