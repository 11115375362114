<template>
<div class="def-container">
    <div class="label-text">{{ property.abbreviation ? property.abbreviation : property.name }}:</div>
    <div class="def-value">{{ value }}</div>
</div>
</template>

<script>
import surveyUtil from "@/common/surveyUtil";
import StringUtil from "@/common/utils/StringUtil";

export default {
    name: "YtDefaultDisplay",
    props:{
        property: {
            type: Object,
            required: true
        },
    },
    computed:{
        surveyPropertyType() {
            return this.$constant.surveyPropertyType
        },
        value() {
            const property = this.property
            let value = surveyUtil.findValueFromProperty(property)
            if (property.id === 'phone')
                return StringUtil.hidePhone(value)
            //科室原来的输入框,后来改成了下拉框
            if (property.id === 'keshi' && !value) {
                value = property.propertyData.content
            }
            //下次随访原来是下拉框,后来改成日期选择
            if (property.id === 'xcsf' && !value) {
                const option = property.options.find(op => op.id === property.propertyData.single)
                if (option)
                    value = option.name
            }

            if (!value)
                value = ''
            if (property.suffix)
                value += property.suffix
            return value
        }
    }
}
</script>

<style scoped>
.def-container {
    display: flex;
    flex-direction: row;
}
.label-text {
    margin-right: 5px;
    white-space: nowrap;
}
.def-value {
    text-align: left;
}
</style>